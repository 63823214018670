import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const LogoListMain = styled.div`
  text-align: center;
`

export const LogoListBrands = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  ${mq.desk} {
    flex-direction: row;
  }
`

export const LogoListLogo = styled.img`
  flex: 1;
  margin-bottom: 4.4rem;
  max-height: 6rem;
  max-width: ${props => `${props.maxWidth / 12}rem`};

  ${mq.desk} {
    margin-bottom: 0;
  }

  ${mq.deskL} {
    max-width: ${props => `${props.maxWidth / 10}rem`};
  }
`
