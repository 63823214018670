import React from 'react'
import { graphql } from 'gatsby'

// Components
import Seo from '../components/Seo'
import Hero from '../components/WhoWeAre/Hero'
import Team from '../components/WhoWeAre/Team'
import Logos from '../components/WhoWeAre/Logos'

const WhoWeAre = ({ data }) => {
  const {
    seoTitle,
    seoDescriptions,
    slug,
    heroLabel,
    heroTitle,
    heroText,
    heroImage,
    teams,
    investors,
    technologyPartners,
  } = data.contentfulWhoWeAre

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescriptions?.seoDescriptions}
        pathname={slug}
      />
      <Hero
        label={heroLabel}
        title={heroTitle}
        text={heroText}
        image={heroImage}
      />
      {React.Children.toArray(teams.map(item => <Team {...item} />))}
      <Logos investors={investors} technologyPartners={technologyPartners} />
    </>
  )
}

export default WhoWeAre

export const pageQuery = graphql`
  query WhoWeAre($slug: String!) {
    contentfulWhoWeAre(slug: { eq: $slug }) {
      seoTitle
      seoDescriptions {
        seoDescriptions
      }
      seoImage {
        gatsbyImageData
      }
      slug
      heroLabel
      heroTitle
      heroText {
        raw
      }
      heroImage {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
        description
        file {
          details {
            image {
              width
            }
          }
        }
      }
      teams {
        ... on ContentfulTeamGroup {
          contentful_id
          title
          team {
            ... on ContentfulTeamMember {
              contentful_id
              photo {
                gatsbyImageData(quality: 100)
                description
                file {
                  details {
                    image {
                      width
                    }
                  }
                }
              }
              name
              position
              linkedIn
              bio {
                bio
              }
            }
          }
        }
      }
      investors {
        file {
          url
          contentType
          details {
            image {
              width
            }
          }
        }
        description
      }
      technologyPartners {
        file {
          url
          contentType
          details {
            image {
              width
            }
          }
        }
        description
      }
    }
  }
`
