import React from 'react'
import PropTypes from 'prop-types'
import { LogoListBrands, LogoListLogo, LogoListMain } from './index.style'
import { TextBodyLarge } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import Spacer from '../../Spacer'

const LogoList = ({ title, brands }) => (
  <LogoListMain>
    <TextBodyLarge>
      <AnimateSplitText>{title}</AnimateSplitText>
    </TextBodyLarge>

    <Spacer size={40} />

    <LogoListBrands>
      {React.Children.toArray(
        brands.map((brand, brandIndex) => (
          <AnimateSlideIn delay={brandIndex * 0.05}>
            <LogoListLogo
              src={brand.file.url}
              alt={brand.description}
              maxWidth={
                brand.file.contentType === 'image/svg+xml'
                  ? brand.file.details.image.width
                  : brand.file.details.image.width / 3
              }
            />
          </AnimateSlideIn>
        ))
      )}
    </LogoListBrands>

    <Spacer size={[50, 110]} />
  </LogoListMain>
)

LogoList.propTypes = {
  title: PropTypes.string,
  brands: PropTypes.array,
}

export default LogoList
