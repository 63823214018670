import styled from 'styled-components'
import { transparentize } from 'polished'
import { colors } from '../../../styles/vars/colors.style'

export const TeamMain = styled.div`
  border-top: 0.1rem solid ${transparentize(0.9, colors.dark)};
  position: relative;
`

export const TeamContent = styled.div``

export const TeamDetails = styled.div``

export const TeamList = styled.div``
