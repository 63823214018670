import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const LinkedIn = ({
  width = 14,
  height = 13,
  fill = colors.light,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 13"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M14 7.9708V13H10.9993V8.30703C10.9993 7.12818 10.5654 6.32382 9.47926 6.32382C8.64991 6.32382 8.15647 6.86596 7.93969 7.39039C7.86027 7.57796 7.84009 7.8389 7.84009 8.10144V13H4.83842C4.83842 13 4.87878 5.05197 4.83842 4.22832H7.83977V5.47202C7.83358 5.48119 7.82577 5.49131 7.81991 5.50049H7.83977V5.47202C8.23849 4.87516 8.95066 4.02273 10.5446 4.02273C12.5193 4.02241 14 5.27591 14 7.9708ZM1.69841 0C0.671813 0 0 0.654428 0 1.5154C0 2.35708 0.650981 3.03175 1.66 3.03175H1.68018C2.72663 3.03175 3.37762 2.3574 3.37762 1.5154C3.35678 0.654428 2.72533 0 1.69841 0ZM0.178369 13H3.17874V4.22832H0.178369V13Z"
        fill={fill}
      />
    </svg>
  )
}

export default LinkedIn
