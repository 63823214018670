import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Heading2 } from '../../TextStyles'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import AnimateSplitText from '../../animation/AnimateSplitText'
import Spacer from '../../Spacer'
import TeamItem from '../TeamItem'
import { TeamMain, TeamContent, TeamDetails, TeamList } from './index.style'

const Team = ({ title, team }) => {
  const [randomDots, setRandomDots] = useState([]),
    [randomXs, setRandomXs] = useState([]),
    [randomYs, setRandomYs] = useState([])

  useEffect(() => {
    const dots = [],
      xs = [],
      ys = []

    team.forEach(item => {
      dots.push(Math.random())
      xs.push(Math.random())
      ys.push(Math.random())
    })

    setRandomDots(dots)
    setRandomXs(xs)
    setRandomYs(ys)
  }, [team])

  return (
    <TeamMain>
      <Spacer size={[60, 150]} />
      <Container>
        <Grid>
          <GridItem tabletP={14} tabletPStart={2}>
            <TeamContent>
              <Heading2>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading2>
              <Spacer size={[40, 80]} />
            </TeamContent>
          </GridItem>
          <GridItem tabletP={14} tabletPStart={2}>
            <TeamDetails>
              <TeamList>
                <Grid columnCount={12}>
                  {React.Children.toArray(
                    team.map((item, itemIndex) => (
                      <GridItem mobileL={6} desk={4}>
                        <TeamItem
                          itemIndex={itemIndex}
                          randomDot={randomDots[itemIndex] || 0}
                          randomX={randomXs[itemIndex] || 0}
                          randomY={randomYs[itemIndex] || 0}
                          {...item}
                        />
                      </GridItem>
                    ))
                  )}
                </Grid>
              </TeamList>
            </TeamDetails>
          </GridItem>
        </Grid>
      </Container>
      <Spacer size={[80, 150]} />
    </TeamMain>
  )
}

Team.propTypes = {
  title: PropTypes.string,
  team: PropTypes.array,
  teamIndex: PropTypes.number,
}

export default Team
