import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Label, TextBody, TextBodyXSmall } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateFadeIn from '../../animation/AnimateFadeIn'
import Spacer from '../../Spacer'
import ToggleCross from '../../svgs/ToggleCross'
import {
  TeamItemMain,
  TeamBackground,
  TeamItemLinkedIn,
  TeamPhoto,
  TeamDot,
  TeamItemScroller,
  TeamItemBio,
  TeamItemToggleIcon,
  TeamItemLinkedInMain,
  TeamBackgroundInner,
} from './index.style'
import Dot from '../../svgs/Dot'
import DotHalf from '../../svgs/DotHalf'
import LinkedIn from '../../svgs/LinkedIn'
import AnimateSlideIn from '../../animation/AnimateSlideIn'

const TeamItem = ({
  photo,
  name,
  position,
  linkedIn,
  bio,
  itemIndex,
  randomDot,
  randomX,
  randomY,
}) => {
  const $bio = useRef()
  const [open, setOpen] = useState(false)

  const Decoration = randomDot > 0.65 ? Dot : DotHalf,
    dotX = randomX > 0.5 ? 0 : 100,
    dotY = randomY * 60 + 20

  const handleClick = () => {
    if (bio) {
      if (!open) {
        $bio.current.scrollTop = 0
      }

      setOpen(!open)
    }
  }

  return (
    <AnimateSlideIn>
      <TeamItemMain onClick={handleClick} toggleable={bio}>
        <TeamBackground>
          <TeamBackgroundInner />
        </TeamBackground>

        <AnimateFadeIn>
          <TeamPhoto open={open}>
            <GatsbyImage
              image={photo.gatsbyImageData}
              alt={photo.description}
              height={photo?.gatsbyImageData?.height}
              width={photo?.gatsbyImageData?.width}
            />
            {Decoration && (
              <TeamDot x={dotX} y={dotY} open={open}>
                <Decoration />
              </TeamDot>
            )}
          </TeamPhoto>
        </AnimateFadeIn>

        <TeamItemScroller open={open}>
          <Spacer size={[25, 35]} />

          <Label>
            <AnimateSplitText delay={0.1}>{name}</AnimateSplitText>
          </Label>

          <Spacer size={10} />

          {position && (
            <>
              <TextBody>
                <AnimateSplitText delay={0.2}>{position}</AnimateSplitText>
              </TextBody>

              {bio && <Spacer size={[20, 25]} />}
            </>
          )}

          {bio && (
            <TeamItemBio ref={$bio} open={open}>
              <TextBodyXSmall>
                <AnimateSplitText delay={0.3}>{bio.bio}</AnimateSplitText>
              </TextBodyXSmall>
            </TeamItemBio>
          )}
        </TeamItemScroller>

        {bio && (
          <TeamItemToggleIcon open={open} aria-label="Read Bio">
            <AnimateSlideIn delay={0.4}>
              <ToggleCross open={false} />
            </AnimateSlideIn>
          </TeamItemToggleIcon>
        )}

        {linkedIn && (
          <TeamItemLinkedIn
            href={linkedIn}
            aria-label={`Visit ${name}'s LinkedIn`}
            target="_blank"
            onClick={e => e.stopPropagation()}
          >
            <AnimateSlideIn delay={0.5}>
              <TeamItemLinkedInMain>
                <LinkedIn />
              </TeamItemLinkedInMain>
            </AnimateSlideIn>
          </TeamItemLinkedIn>
        )}
      </TeamItemMain>
    </AnimateSlideIn>
  )
}

TeamItem.propTypes = {
  photo: PropTypes.object,
  name: PropTypes.string,
  position: PropTypes.string,
  linkedin: PropTypes.string,
  bio: PropTypes.object,
  itemIndex: PropTypes.number,
  randomDot: PropTypes.number,
  randomX: PropTypes.number,
  randomY: PropTypes.number,
}

export default TeamItem
