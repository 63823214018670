import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { mq } from '../../../styles/vars/media-queries.style'

export const HeroMain = styled.div`
  position: relative;
`

export const HeroContent = styled.div`
  position: relative;
  z-index: 1;
`

export const HeroImage = styled.div`
  ${clamp('padding-right', 31, 50, breakpoints.desk, breakpoints.container)}
  ${clamp('padding-left', 31, 50, breakpoints.desk, breakpoints.container)}
`

export const HeroText = styled.div`
  max-width: 67.4rem;
  ${clamp('padding-bottom', 40, 120)}

  ${mq.tabletL} {
    padding-bottom: 0;
  }
`
