import React from 'react'
import PropTypes from 'prop-types'
import Spacer from '../../Spacer'
import { Heading2, TextBodySmall } from '../../TextStyles'
import DotLabel from '../../DotLabel'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import RichText from '../../RichText'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { HeroImage, HeroContent, HeroMain, HeroText } from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateFadeIn from '../../animation/AnimateFadeIn'

const Hero = ({ label, title, text, image }) => (
  <HeroMain>
    <Spacer size={[150, 264]} />

    <Container>
      <Grid>
        <GridItem
          tabletP={12}
          tabletPStart={2}
          tabletL={8}
          tabletLStart={2}
          deskL={7}
          deskLStart={2}
        >
          <HeroContent>
            <Spacer size={[20, 40]} />

            <DotLabel>
              <TextBodySmall>{label}</TextBodySmall>
            </DotLabel>

            <Spacer size={[30, 60]} />

            <Heading2 as={`h1`} maxWidth={11.3}>
              <AnimateSplitText delay={0.1}>{title}</AnimateSplitText>
            </Heading2>

            <Spacer size={[20, 35]} />

            <HeroText>
              <RichText content={text} paragraphSize="large" delay={0.2} />
            </HeroText>
          </HeroContent>
        </GridItem>
        <GridItem
          tabletP={11}
          tabletPStart={4}
          tabletL={7}
          tabletLStart={10}
          tabletLAlign={`flex-end`}
          deskL={7}
          deskLStart={9}
        >
          <HeroImage>
            <AnimateFadeIn>
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.description}
              />
            </AnimateFadeIn>
          </HeroImage>
        </GridItem>
      </Grid>
    </Container>
    <Spacer size={[60, 180]} />
  </HeroMain>
)

Hero.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.object,
  image: PropTypes.object,
}

export default Hero
