import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { font } from '../../../styles/vars/font.style'
import { Label, TextBody } from '../../../styles/vars/textStyles.style'
import { easings } from '../../../styles/vars/easings.style'
import { colors } from '../../../styles/vars/colors.style'

export const TeamItemMain = styled.div`
  position: relative;
  ${clamp('height', 332, 452)};
  ${clamp('padding-top', 40, 70)};
  width: 100%;
  overflow: hidden;
  cursor: ${props => (props.toggleable ? 'pointer' : undefined)};
  text-align: center;
  ${clamp('border-radius', 10, 20)}
`

export const TeamBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  z-index: -1;
`

export const TeamBackgroundInner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${colors.grey};
`

export const TeamItemLinkedIn = styled.a`
  position: absolute;
  ${clamp('right', 19, 24)}
  ${clamp('top', 15, 20)}
`

export const TeamItemLinkedInMain = styled.div`
  display: block;
  padding: 0.5rem 1.3rem 0.6rem;
  background-color: ${colors.green};
  border-radius: 1.2rem;
`

export const TeamPhoto = styled.div`
  ${clamp('height', 119, 165)}
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  opacity: ${props => (props.open ? 0 : 1)};
  position: absolute;
  ${clamp('top', 40, 70)};
  transform: translateX(-50%) translateY(${props => (props.open ? `2rem` : 0)});
  transition: opacity 0.4s ${easings.inOut.default},
    transform 0.4s ${easings.inOut.default};
  transition-delay: ${props => (props.open ? 0 : 0.2)}s;
  ${clamp('width', 119, 165)}

  img {
    ${clamp('border-radius', 7, 10)}
    overflow: hidden;
  }
`

export const TeamDot = styled.div`
  left: ${props => props.x}%;
  position: absolute;
  top: ${props => props.y}%;
  transform: translateX(-50%) translateY(-50%)
    translateY(${props => (props.open ? 5 : 0)}rem);
  transition: opacity ${props => (props.open ? 0.4 : 0.6)}s
      ${easings.inOut.default},
    transform ${props => (props.open ? 0.4 : 0.6)}s ${easings.inOut.default};
  transition-delay: ${props => (props.open ? 0 : 0.15)}s;
  z-index: 2;
  mix-blend-mode: multiply;
`

export const TeamItemScroller = styled.div`
  height: 85%;
  transform: translateY(
    ${props =>
      props.open
        ? `clamp(-3.5rem,-2.2368rem - 0.7018vw,-2.5rem)`
        : `clamp(11.9rem, 10.6895rem + 3.2281vw, 16.5rem)`}
  );
  transition: transform 0.4s ${easings.inOut.default};
  transition-delay: ${props => (props.open ? 0.1 : 0.1)}s;

  ${Label} {
    font-weight: ${font.primary.weight.regular};
    opacity: 1;
    white-space: nowrap;
  }

  ${TextBody} {
    font-weight: ${font.primary.weight.light};
    opacity: 0.61;
  }
`

export const TeamItemBio = styled.div`
  height: 57%;
  overflow-y: scroll;
  left: 0;
  opacity: ${props => (props.open ? 1 : 0)};
  ${clamp('padding-left', 25, 45)};
  ${clamp('padding-right', 25, 45)};
  pointer-events: ${props => (props.open ? `` : `none`)};
  position: ${props => (props.open ? `relative` : `absolute`)};
  right: 0;
  transition: opacity 0.4s ${easings.inOut.default};
  transition-delay: ${props => (props.open ? 0.3 : 0)}s;
`

export const TeamItemToggleIcon = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  ${clamp('bottom', 40, 50)};
  color: currentColor;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) rotateZ(${props => (props.open ? 45 : 0)}deg);
  transition: transform 0.2s ${easings.inOut.default};
  ${clamp('width', 14, 25)}
`
