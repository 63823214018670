import React from 'react'
import PropTypes from 'prop-types'
import { LogosMain } from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import LogoList from '../LogoList'

const Logos = ({ investors, technologyPartners }) => (
  <LogosMain>
    <Spacer size={[80, 180]} />
    <Container>
      <Grid>
        <GridItem desk={7} deskStart={2}>
          <LogoList title="Investors" brands={investors} />
        </GridItem>
        <GridItem desk={7} deskStart={9}>
          <LogoList title="Technology partners" brands={technologyPartners} />
        </GridItem>
      </Grid>
    </Container>
    <Spacer size={[50, 110]} />
  </LogosMain>
)

Logos.propTypes = {
  investors: PropTypes.array,
  technologyPartners: PropTypes.array,
}

export default Logos
